<template>
  <div>
    <div
      v-if="(products || []).length > 0"
      class="template-list-recently"
      :class="rootClassList"
    >
      <div class="template-list-recently__head">
        <div class="h2" v-if="title" v-html="title"/>
        <nuxt-link to="/recently" class="hover-decoration">
          Посмотреть все просмотренные
          <img src="~/assets/img/svg/common/arrow.svg"/>
        </nuxt-link>
      </div>
      <div class="template-list-recently__body">
        <slot :products="products"/>
      </div>
      <div class="template-list-recently__footer">
        <nuxt-link to="/recently" class="btn btn-outline">
          Посмотреть все просмотренные
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Product } from '#sitis/internal/controllers/products/models/Product';
import type { ComputedRef } from 'vue';

const props = defineProps<{
  type: string;
  title: string;
  perPage?: number
  server?: boolean;
  classRoot?: string;
  ignoreProductId?: number | string;
}>();
const {
  $api
} = useNuxtApp();

const getProducts = async () => {
  let filter = [
    `is_product_set=${Number(Boolean(props.type === 'collections'))}`,
    'expand=short_characteristics,promotions,promotions.icon'
  ];

  return $api.agent.get(`/recently-viewed?${ filter.join('&') }`).then((res: any) => {
    let _data = res?._data || [];
    if (props.perPage) {
      _data = _data.splice(0, props.perPage);
    }
    if (props.ignoreProductId) {
      _data.splice(_data.findIndex((t: any) => String(t.id) === String(props.ignoreProductId)), 1)
    }

    return (_data || []).map((t: any) => new Product(t).toJSON())
  }).catch(() => {
    return []
  })
};
const {
  data: products
} = useLazyAsyncData(`get-products-template-list-recently-${ props.type }`, () => getProducts(), {
  server: Boolean(props.server)
});

const rootClassList: ComputedRef<string> = computed(() => {
  let _list: string[] = [];

  if (props.classRoot) {
    _list.push(props.classRoot)
  }

  return _list.join(' ')
})

</script>

<style scoped lang="scss">
.template-list-recently__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  a {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;

    img {
      width: 18px;
      height: 18px;
      margin-left: 6px;
      object-fit: contain;
      object-position: center;
      transform: rotate(180deg);
    }
  }
}
.template-list-recently__footer {
  display: none;
  margin-top: 32px;
}

@media (max-width: 1023px) {
  .template-list-recently__head a {
    display: none;
  }
  .template-list-recently__footer {
    display: flex;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      border-radius: 12px;
      border-color: #404040;

      font-weight: 600;
    }
  }
}
</style>
